<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    width="90%"
    center
    :close-on-click-modal="false"
  >
    <div style="height: 60vh">
      <div class="mb-3" v-for="(duplicate, $index) in data" :key="$index">
        Невозможно акцептовать событие: Номер технолога - {{ duplicate.eventCardNumber}}
        <div class="mt-1" v-if="duplicate.functionCardNumber">
          Дубликат орг. причины ({{ duplicate.staffCulpabilityCode }} - {{ duplicate.staffCategoryCode }} - {{ duplicate.organizationName }})
          в карточке функции пользователя. Игнорируйте новую запись.
        </div>
        <div class="mt-1" v-if="duplicate.secondEquipmentCardNumber">
          Дубликат орг. причины ({{ duplicate.staffCulpabilityCode }} - {{ duplicate.staffCategoryCode }} - {{ duplicate.organizationName }})
          в карточке уРЗА пользователя. Игнорируйте новую запись.
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'StaffCulpabilitiesDuplicatesModal',
  props: ['value', 'data'],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
  }
};
</script>

<style scoped>

</style>
