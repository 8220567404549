<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    width="90%"
    height="100%"
    center
    fullscreen
    :close-on-click-modal="false"
  >
    <div slot="title">
      Выберите событие для сопоставления
    </div>
    <events-select-modal-filter @active-change="filterActive = $event" :filter-model="filterModel" @clear-filters="clearFilters"/>
    <el-row class="m-0" :gutter="20">
      <el-col :span="24">
        <pagination
          style="text-align: center"
          :total-count="totalCount"
          :page-size.sync="pageSize"
          :current-page.sync="pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentPageChange"
        />
      </el-col>
    </el-row>
    <div>
      <el-table
        v-loading="loading"
        style="width: 100%"
        height="45vh"
        :data="events"
        highlight-current-row
        @current-change="handleCurrentChange"
      >
<!--        <el-table-column
          type="selection"
          width="55">
        </el-table-column>-->
        <el-table-column prop="eventTime" label="Дата и время события" :formatter="dateTimeRenderer" />
        <el-table-column prop="energySystem" label="Энергосистема" />
        <el-table-column prop="energyObjects" label="Энергообъект" />
        <el-table-column prop="primaryEquipmentName" label="Первичное оборудование/ЛЭП" />
        <el-table-column prop="baseVoltageName" label="Напряжение" />
      </el-table>
    </div>
    <div class="mt-2 mb-0" style="text-align: center">
        <el-button :disabled="!currentRow" type="primary" @click="handleMatch">
          Выбрать
        </el-button>
    </div>
  </el-dialog>
</template>

<script>
import eventsApi from '@/api/eventsAndIncidents/events/';
import eventsMatchApi from '@/api/eventsAndIncidents/eventsMatch';
import formatters from '@/mixins/formatters';
import pagination from '@/components/Ui/Pagination';
import dateHelpers from '@/mixins/dateHelpers';
import EventsSelectModalFilter from '@/views/EventsAndIncidents/EventsMatch/EventsSelectModalFilter';

export default {
  name: 'EventSelectModal',
  props: ['value', 'selectedEventMatch', 'dataImportSessionId'],
  components: { EventsSelectModalFilter, pagination },
  mixins: [formatters, dateHelpers],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    filterModel: {
      handler() {
        this.getEvents();
      },
      deep: true
    },
  },
  data() {
    return {
      loading: false,
      events: [],
      currentRow: null,
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      eventCard: {},
      filterModel: {
        startEventTimeFilter: '',
        endEventTimeFilter: '',
        primaryEquipmentFilter: [],
        baseVoltageFilter: [],
        energyObjectFilter: [],
        eoRegionFilter: [],
        showDeleted: false,
      },
      filterActive: true,
    };
  },
  async mounted() {
    /* this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    }); */
    await this.getEvents();
    // this.tableHeight = this.getTableHeight();
  },
  methods: {
    async getEvents() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        startEventTimeRange: this.filterModel.startEventTimeFilter ? this.dateSetter(this.filterModel.startEventTimeFilter) : null,
        endEventTimeRange: this.filterModel.endEventTimeFilter ? this.dateSetter(this.filterModel.endEventTimeFilter) : null,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        energyObjectFilter: this.filterModel.energyObjectFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        primaryEquipmentFilter: this.filterModel.primaryEquipmentFilter,
        secondEquipmentPanelFilter: [],
        dCOrganizationFilter: [],
        psrTypeFilter: [],
        estimationFilter: [],
        shortCircuitFormFilter: [],
        shortCircuitPlaceFilter: [],
        showDeleted: this.filterModel.showDeleted,
        onlyPrimaryEquipments: true, // исключает события, у которых есть ссылка на УРЗА
        sortField: this.sortField,
        sortDesc: this.sortDesc
      };
      const res = await eventsApi.getEventsWithPagination(params);
      if (res.data) {
        this.events = res.data.items;
        this.totalCount = res.data.totalCount;
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getEvents();
    },
    async handleCurrentPageChange() {
      await this.getEvents();
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    async handleMatch() {
      // console.log(this.selectedEventMatch);
      // console.log(this.currentRow);

      this.loading = true;
      const res = await eventsMatchApi.matchUserAndPublicEvent(
        this.dataImportSessionId,
        this.selectedEventMatch.userEventCard.id,
        this.currentRow.id
      );
      if (res.data) {
        // this.selectedEventMatch = res.data;
        this.$emit('update', res.data);
        this.dialogVisible = false;
      }
      this.loading = false;
    },
    clearFilters() {
      this.filterModel = {
        startEventTimeFilter: '',
        endEventTimeFilter: '',
        primaryEquipmentIdFilter: [],
        baseVoltageFilter: [],
        energyObjectFilter: [],
        eoRegionIdFIlter: [],
        eoRegionFilter: [],
        showDeleted: false,
      };
    }
  },
};
</script>

<style scoped>

</style>
