<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    width="90%"
    center
    :close-on-click-modal="false"
  >
    <div style="height: 60vh">
      <div class="mb-3" v-for="(duplicate, $index) in data" :key="$index">
        Невозможно акцептовать событие: Номер технолога - {{ duplicate.publicEventCard.number}}, Номер пользователя - {{ duplicate.userEventCard.number}}
        <div class="mt-1" v-if="duplicate.hasUserCardDuplicate">
          Наличие дубликата по карточкам события пользователя. Игнорируйте одну из записей или разорвите сопоставление.
        </div>
        <div class="mt-1" v-if="duplicate.functionCardDuplicates && duplicate.functionCardDuplicates.length > 0">
          Наличие дубликатов по карточкам функций пользователя: {{ getUniqueCardIds(duplicate.functionCardDuplicates, 'userFunctionCard') }}.
          Игнорируйте одну из записей или разорвите сопоставление.
        </div>
        <div class="mt-1" v-if="duplicate.secondEquipmentCardDuplicates && duplicate.secondEquipmentCardDuplicates.length > 0">
          Наличие дубликатов по карточкам уРЗА пользователя: {{ getUniqueCardIds(duplicate.secondEquipmentCardDuplicates, 'userSecondEquipmentCard') }}.
          Игнорируйте одну из записей или разорвите сопоставление.
        </div>
        <div class="mt-1" v-if="duplicate.secondEquipmentTypeCardDuplicates && duplicate.secondEquipmentTypeCardDuplicates.length > 0">
          Наличие дубликатов по карточкам комплексов РЗА пользователя: {{ getUniqueCardIds(duplicate.secondEquipmentTypeCardDuplicates, 'userSecondEquipmentTypeCard') }}.
          Игнорируйте одну из записей или разорвите сопоставление.
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'DuplicatesModal',
    props: ['value', 'data'],
    computed: {
      dialogVisible: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
    methods: {
      handleClose() {
        this.dialogVisible = false;
      },
      getUniqueCardIds(cardList, list) {
        if (cardList) {
          const idList = cardList.map((t) => (t[list] && t[list].number ? t[list].number : null));
          return [...new Set(idList)].join(', ');
        }
        return null;
      }
    }
  };
</script>

<style scoped>

</style>
