<template>
  <div class="events_match">
    <div>
      <div>
        <!-- <router-link v-if="showBackLink" :to="{ name: 'UserUploads' }"> -->
          <el-button class="pt-0 mr-4" type="text" icon="el-icon-back" @click="$router.back()">Назад</el-button>
        <!-- </router-link> -->
        <span v-if="dataImportSession"> Сопоставление данных файла - {{ dataImportSession.fileName }} </span>
      </div>
      <div class="m-2 mt-0" v-if="!this.eventCardId">
        <div style="display: inline-block;">
          <el-checkbox @change="applyFilter" v-model="filter.showCurrentDC">Отображать события моего ДЦ</el-checkbox>
        </div>
      </div>
      <div v-if="!readonly" class="m-2 mt-0">
        <div style="display: inline-block;">
          <el-checkbox @change="applyFilter" v-model="filter.showReviewed">Рассмотренные</el-checkbox>
          <el-checkbox @change="applyFilter" v-model="filter.showNotReviewed">Не рассмотренные</el-checkbox>
        </div>
      </div>
      <div class="m-2">
        <div style="display: inline-block;">
          <el-checkbox @change="applyFilter" v-model="filter.showSuccess">Успешные</el-checkbox>
          <el-checkbox @change="applyFilter" v-model="filter.showConflict">Конфликтные</el-checkbox>
          <el-checkbox @change="applyFilter" v-model="filter.showNew">Новые</el-checkbox>
          <el-checkbox @change="applyFilter" v-model="filter.showDivergence">Расхождения</el-checkbox>
        </div>
        <template v-if="!readonly">
          <div v-if="isAcceptCompleted" style="display: inline-block; float: right">
            Сопоставление завершено
          </div>
          <div v-else style="display: inline-block; float: right">
            <el-button :disabled="ignoreDisabled()" class="p-0 mr-2" @click="ignoreMapping" type="text" icon="el-icon-circle-close">Игнорировать</el-button>
            <el-button :disabled="matchManuallyDisabled()" class="p-0 mr-2" @click="matchManually" type="text" icon="el-icon-connection">Сопоставить вручную</el-button>
            <el-button :disabled="breakMappingDisabled()" class="p-0 mr-2" @click="breakMapping" type="text" icon="el-icon-remove-outline">Разорвать сопоставление</el-button>
          </div>
        </template>
      </div>
    </div>
    <div>
      <el-row class="m-0" :gutter="20">
        <el-col :span="24">
          <pagination
            class="text-center"
            :total-count="totalCount"
            :page-size.sync="pageSize"
            :current-page.sync="pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
      <el-table style="width: 100%;"
                v-loading="loading"
                class="compare-table"
                :data="itemsForCurrentPage"
                :height="`calc(${ this.getTableHeight() } - 30px)`"
                :row-class-name="getRowClassName"
                @selection-change="handleSelectionChange">
        <el-table-column v-if="!isAcceptCompleted"
                         type="selection" :selectable="rowSelectable"
                         width="55">
        </el-table-column>
        <el-table-column prop="matchStatus"
                         label=""
                         width="50">
          <template slot-scope="scope">
            <div style="text-align: center">
              <i v-if="scope.row.eventCardMatchStatus === matchStatuses.divergence"
                 style="font-size: 1.4rem"
                 class="event-compare-divergence">
                <QuestionIcon />
              </i>
              <i v-else-if="scope.row.eventCardMatchStatus === matchStatuses.conflict"
                 style="font-size: 1.4rem"
                 class="el-icon-warning-outline event-compare-conflict" />
              <i v-else-if="scope.row.eventCardMatchStatus === matchStatuses.newRow"
                 style="font-size: 1.4rem"
                 class="el-icon-circle-plus-outline event-compare-new" />
              <i v-else-if="scope.row.eventCardMatchStatus === matchStatuses.success"
                 style="font-size: 1.4rem"
                 class="el-icon-circle-check event-compare-success" />
              <i v-else-if="scope.row.eventCardMatchStatus === matchStatuses.undef"
                 style="font-size: 1.4rem"
                 class="el-icon-circle-plus-outline event-compare-new" />
            </div>
          </template>
        </el-table-column>
        <!--        <el-table-column label="ИД">
      <el-table-column
        prop="publicEventCard.id"
        label="Текущее"
        min-width="15">
      </el-table-column>
      <el-table-column
        prop="userEventCard.id"
        label="Импорт"
        min-width="15">
      </el-table-column>
    </el-table-column>-->
        <el-table-column label="Номер события">
          <el-table-column prop="publicEventCard.number"
                           label="Текущее"
                           min-width="10">
            <template slot-scope="scope">
              <span :class="[ scope.row.eventCardMatchStatus === matchStatuses.newRow ? 'event-compare-new' : '']" v-if="scope.row.publicEventCard">
                {{ scope.row.publicEventCard.number }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userEventCard.number"
                           label="Импорт"
                           min-width="10">
            <template slot-scope="scope">
              <span :class="[ scope.row.eventCardMatchStatus === matchStatuses.newRow ? 'event-compare-new' : '']">
                {{ scope.row.userEventCard.number }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Дата и время">
            <template
              slot="header">
              <el-popover
                ref="fromPopOverDatetime"
                placement="top-start"
                width="250"
                trigger="hover">
                <span class="manual-match-tooltip">
                Для выбора данных, которые требуется перенести в Систему, необходимо нажать левой кнопкой мышки на нужное значение. При акцепте будет произведена запись параметров, текст которых выделен полужирным. Значения, выделенные серым не будут записаны в Систему.
                </span>
              </el-popover>
              <span>Дата и время <i
                v-popover:fromPopOverDatetime
                class="el-icon-info
                text-blue" />
              </span>
            </template>
          <el-table-column prop="publicEventCard.eventTime"
                           label="Текущее"
                           min-width="10"
                           :formatter="dateTimeRenderer">
            <template slot-scope="scope">
              <div
                v-if="scope.row.publicEventCard"
                @click="denySingleManualMatch(scope.row, 'eventTime')"
                :class="[ getParamMatchClass(scope.row, 'eventTime'), getPublicSingleManualMatchClass(scope.row, 'eventTime')]"
              >
                <template v-if="scope.row.eventTimeManualMatched">
                  {{ moment(String(scope.row.backupData.eventTime)).add(userTimeZone, 'hours').format('DD.MM.YYYY HH:mm:ss') }}
                </template>
                <template v-else>
                  {{ moment(String(scope.row.publicEventCard.eventTime)).add(userTimeZone, 'hours').format('DD.MM.YYYY HH:mm:ss') }}
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="userEventTime"
                           label="Импорт"
                           min-width="10"
                           class-name="manual-match">
            <template slot-scope="scope">
              <div
                @click="applySingleManualMatch(scope.row, 'eventTime')"
                :class="[ getParamMatchClass(scope.row, 'eventTime'), getUserSingleManualMatchClass(scope.row, 'eventTime') ]"
              >
                {{ moment(String(scope.row.userEventCard.eventTime)).add(userTimeZone, 'hours').format('DD.MM.YYYY HH:mm:ss') }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="GUID ПК «Анализ 2009»">
          <el-table-column prop="publicPrimaryEquipmentAnalysisIds"
                           label="Текущее"
                           min-width="15">
            <template slot-scope="scope">
              <span :class="[ scope.row.eventCardMatchStatus === matchStatuses.newRow ? 'event-compare-new' : '']" v-if="scope.row.publicEventCard">
                <template v-for="(analysisId, $index) in scope.row.publicEventCard.primaryEquipmentAnalysisIds">
                  <span :key="$index">
                    {{ analysisId }};
                  </span>
                </template>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userPrimaryEquipmentAnalysisIds"
                           label="Импорт"
                           min-width="15">
            <template slot-scope="scope">
              <span :class="[ scope.row.eventCardMatchStatus === matchStatuses.newRow ? 'event-compare-new' : '']">
                <template v-for="(analysisId, $index) in scope.row.userEventCard.primaryEquipmentAnalysisIds">
                  <span :key="$index">
                    {{ analysisId }};
                  </span>
                </template>
              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Наименование первичного оборудования">
          <el-table-column prop="publicPrimaryEquipmentName"
                           label="Текущее"
                           min-width="15">
            <template slot-scope="scope">
              <span v-if="scope.row.publicEventCard" :class="getEquipmentNameClassByStatus(scope.row)">
                {{ scope.row.publicEventCard.primaryEquipmentName }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userPrimaryEquipmentName"
                           label="Импорт"
                           min-width="15">
            <template slot-scope="scope">
              <span :class="getEquipmentNameClassByStatus(scope.row)">
                {{ scope.row.userEventCard.primaryEquipmentName }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Описание">
            <template
              slot="header">
              <el-popover
                ref="fromPopOverDecrip"
                placement="top-start"
                width="250"
                trigger="hover">
                <span class="manual-match-tooltip">
                Для выбора данных, которые требуется перенести в Систему, необходимо нажать левой кнопкой мышки на нужное значение. При акцепте будет произведена запись параметров, текст которых выделен полужирным. Значения, выделенные серым не будут записаны в Систему.
                </span>
              </el-popover>
              <span>Описание <i
                v-popover:fromPopOverDecrip
                class="el-icon-info
                text-blue" />
              </span>
            </template>
          <el-table-column prop="publicDescription"
                           label="Текущее"
                           min-width="15">
            <template slot-scope="scope">
              <div
                v-if="scope.row.publicEventCard"
                @click="denySingleManualMatch(scope.row, 'description')"
                :class="[ getParamMatchClass(scope.row, 'description'), getPublicSingleManualMatchClass(scope.row, 'description') ]"
              >
                <template v-if="scope.row.descriptionManualMatched">
                  {{ scope.row.backupData.description && (scope.row.backupData.description.length > 114) ? scope.row.backupData.description.slice(0,115) + '...' : scope.row.backupData.description }}
                  <el-button v-if="scope.row.backupData.description && (scope.row.backupData.description.length > 114)" type="text" icon="el-icon-d-arrow-right" class="longtext__button"
                             @click="openLongText(scope.row.backupData.description)"></el-button>
                </template>
                <template v-else>
                  {{ scope.row.publicEventCard.description && (scope.row.publicEventCard.description.length > 114) ? scope.row.publicEventCard.description.slice(0,115) + '...' : scope.row.publicEventCard.description }}
                  <el-button v-if="scope.row.publicEventCard.description && (scope.row.publicEventCard.description.length > 114)" type="text" icon="el-icon-d-arrow-right" class="longtext__button"
                             @click="openLongText(scope.row.publicEventCard.description)"></el-button>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="userDescription"
                           label="Импорт"
                           min-width="15"
                           class-name="manual-match">
            <template slot-scope="scope">
              <div
                @click="applySingleManualMatch(scope.row, 'description')"
                :class="[ getParamMatchClass(scope.row, 'description'), getUserSingleManualMatchClass(scope.row, 'description') ]"
              >
                {{ scope.row.userEventCard.description && (scope.row.userEventCard.description.length > 114) ? scope.row.userEventCard.description.slice(0,115) + '...' : scope.row.userEventCard.description }}
                <el-button v-if="scope.row.userEventCard.description && (scope.row.userEventCard.description.length > 114)" type="text" icon="el-icon-d-arrow-right" class="longtext__button"
                           @click="openLongText(scope.row.userEventCard.description)"></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Вид КЗ" min-width="20">
          <el-table-column prop="publicShortCircuitFormCode"
                           label="Текущее" min-width="10">
            <template slot-scope="scope">
              <span v-if="scope.row.publicEventCard">
                {{ scope.row.publicEventCard.shortCircuitFormCode }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userShortCircuitFormCode"
                           label="Импорт" min-width="10">
            <template slot-scope="scope">
              <span>
                {{ scope.row.userEventCard.shortCircuitFormCode }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Место КЗ" min-width="20">
          <el-table-column prop="publicShortCircuitPlaceCode"
                           label="Текущее" min-width="10">
            <template slot-scope="scope">
              <span v-if="scope.row.publicEventCard">
                {{ scope.row.publicEventCard.shortCircuitPlaceCode }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userShortCircuitPlaceCode"
                           label="Импорт" min-width="10">
            <template slot-scope="scope">
              <span>
                {{ scope.row.userEventCard.shortCircuitPlaceCode }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="functionCardMatches"
                         label="Функции РЗА"
                         min-width="7">
          <template slot-scope="scope">
            <span class="span-link"
                  :class="getFunctionCardsClassByStatusAndReview(scope.row)"
                  @click="showFunctionCardsMatchModal(scope.row)">
              {{ getTextByMatchStatus(scope.row.functionCardsMatchStatus) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="secondEquipmentCardMatches"
                         label="Устройства РЗА"
                         min-width="7">
          <template slot-scope="scope">
            <span class="span-link"
                  :class="getSecondEquipmentCardsClassByStatusAndReview(scope.row)"
                  @click="showSecEqCardsMatchesModal(scope.row)">
              {{ getTextByMatchStatus(scope.row.secondEquipmentCardsMatchStatus) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template v-if="!readonly">
      <div style="text-align: center">
        <el-button v-if="!isAcceptCompleted" style="margin-top: 10px" @click="acceptMatching" :disabled="acceptDisabled()" size="small" type="primary">
          Акцептовать
        </el-button>
      </div>
    </template>

    <function-cards-match-modal
      v-if="funcCardsMatchModalVisible"
      v-model="funcCardsMatchModalVisible"
      :items="matchList"
      :review-status="selectedItem.reviewStatus"
      :allow-match="selectedItem.isCurrentDC"
      @all-ignore-handle="selectedItem.functionCardsReviewStatus = reviewStatuses.ignoredNotAccepted"
      @not-all-ignore-handle="selectedItem.functionCardsReviewStatus = reviewStatuses.notReviewed"
      @match-status-changed="handleFunctionCardsMatchStatusChange"
    />
    <second-equipment-cards-match-modal
      v-if="secEqCardsMatchModalVisible"
      v-model="secEqCardsMatchModalVisible"
      :items="matchList"
      :review-status="selectedItem.reviewStatus"
      :allow-match="selectedItem.isCurrentDC"
      @all-ignore-handle="selectedItem.secondEquipmentCardsReviewStatus = reviewStatuses.ignoredNotAccepted"
      @not-all-ignore-handle="selectedItem.secondEquipmentCardsReviewStatus = reviewStatuses.notReviewed"
      @match-status-changed="handleSecondEquipmentCardsMatchStatusChange"
    />
    <event-select-modal v-if="eventSelectModalVisible" v-model="eventSelectModalVisible" :selected-event-match="selectedItems[0]" :data-import-session-id="id"
      @update="handleMatchUpdate"
    />
    <duplicates-modal v-model="duplicatesModalVisible" :data="duplicatesModalData" />
    <staff-culpabilities-duplicates-modal v-model="staffDuplicatesModalVisible" :data="duplicatesModalData" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import remove from 'lodash.remove';
import cloneDeep from 'lodash.clonedeep';
import eventsMatchApi from '@/api/eventsAndIncidents/eventsMatch';
import QuestionIcon from '@/assets/icons/question-icon.svg';
import tableResize from '@/mixins/tableResize';
import formatters from '@/mixins/formatters.js';
import pagination from '@/components/Ui/Pagination';
import SecondEquipmentCardsMatchModal from '@/views/EventsAndIncidents/EventsMatch/SecondEquipmentCardsMatchModal';
import eventCompare from '@/mixins/eventCompare';
import EventSelectModal from '@/views/EventsAndIncidents/EventsMatch/EventSelectModal';
import StaffCulpabilitiesDuplicatesModal
  from '@/views/EventsAndIncidents/EventsMatch/StaffCulpabilitiesDuplicatesModal';
import FunctionCardsMatchModal from './FunctionCardsMatchModal';
import DuplicatesModal from './DuplicatesModal';

export default {
  name: 'EventsMatch',
  props: {
    id: { type: String, default: () => null },
    eventCardId: { type: String, default: () => null },
    readonly: { type: Boolean, default: () => false },
    showBackLink: { type: Boolean, default: () => true },
    tableSizeConstant: { type: Number, default: () => 145 },
  },
  components: {
    StaffCulpabilitiesDuplicatesModal,
    DuplicatesModal,
    EventSelectModal,
    SecondEquipmentCardsMatchModal,
    FunctionCardsMatchModal,
    pagination,
    QuestionIcon
  },
  mixins: [formatters, tableResize, eventCompare],
  data() {
    return {
      loading: false,
      dataImportSession: null,
      // tableSizeConstant: 145,
      allRecords: [],
      filteredItems: [],
      itemsForCurrentPage: [],
      selectedItems: [],
      pageNumber: 1,
      pageSize: 50,
      totalCount: 0,
      filter: {
        showReviewed: true,
        showNotReviewed: true,
        searchByPrimaryEquipment: '',
        showSuccess: true,
        showConflict: true,
        showDivergence: true,
        showNew: true,
        showCurrentDC: true
      },
      funcCardsMatchModalVisible: false,
      secEqCardsMatchModalVisible: false,
      eventSelectModalVisible: false,
      selectedItem: null,  // элемент у которого в текущий момет просматриваются карточки ф-ии/ув/комплексов
      matchList: [],
      duplicatesModalVisible: false,
      staffDuplicatesModalVisible: false,
      duplicatesModalData: [],
      isDataChanged: false, // надо ли показывать окно "Все несохраненные данные будут утеряны"
    };
  },
  computed: {
    ...mapGetters('identity', ['userTimeZone']),
    isAcceptCompleted() {
      if (this.allRecords.length === 0) return false;

      return !this.allRecords.some((r) => r.reviewStatus === this.reviewStatuses.notReviewed
        || r.reviewStatus === this.reviewStatuses.ignoredNotAccepted);
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight =  this.getTableHeight();
    });
    if (this.id) {
      await this.getDataImportSession();
      await this.getEventsMatch();
    } else if (this.eventCardId) {  // если мы переходим из карточки события системы и надо показать связанные события пользователей
      await this.getRelatedEvents();
    }
    this.tableHeight = this.getTableHeight();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isDataChanged) {
      next();
    } else if (!this.isAcceptCompleted) {
      this.$confirm('Все несохраненные данные будут утеряны. Продолжить?', 'Подтверждение', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(() => {
        next();
      }).catch(() => {
        next(false);
      });
    } else {
      next();
    }
  },
  methods: {
    async getDataImportSession() {
      const res = await eventsMatchApi.getDataImportSession(
        this.id,
      );
      if (res.data) {
        this.dataImportSession = res.data;
      }
    },
    async getEventsMatch() {
      this.loading = true;
      const res = await eventsMatchApi.getEventsMatch(
        this.id,
      );
      if (res.data) {
        this.initTableData(res.data);
      }
      this.loading = false;
    },
    async getRelatedEvents() {
      this.loading = true;
      const res = await eventsMatchApi.getRelatedEvents(
        this.eventCardId,
      );
      if (res.data) {
        this.initTableData(res.data);
      }
      this.loading = false;
    },
    initTableData(matchItems) {
      this.allRecords = matchItems;
      this.applyFilter();
      this.getItemsForPage();
    },
    handleSizeChange() {
      this.pageNumber = 1;
      this.getItemsForPage();
    },
    handleCurrentChange() {
      this.getItemsForPage();
    },
    getFunctionCardsClassByStatusAndReview(row) {
      const classByStatus = this.getClassByMatchStatus(row.functionCardsMatchStatus);
      const classByIgnore = this.getClassByReviewStatus(row.functionCardsReviewStatus);
      return `${classByStatus  } ${  classByIgnore}`;
    },
    getSecondEquipmentCardsClassByStatusAndReview(row) {
      const classByStatus = this.getClassByMatchStatus(row.secondEquipmentCardsMatchStatus);
      const classByIgnore = this.getClassByReviewStatus(row.secondEquipmentCardsReviewStatus);
      return `${classByStatus  } ${  classByIgnore}`;
    },
    getEquipmentNameClassByStatus(row) {
      if (row.eventCardMatchStatus === this.matchStatuses.newRow) return 'event-compare-new';
      if (row.eventCardMatchStatus === this.matchStatuses.conflict
        && row.publicEventCard !== null
        && row.publicEventCard.primaryEquipmentId !== row.userEventCard.primaryEquipmentId
      ) return 'event-compare-conflict';
      return '';
    },
    applyFilter() {
      let tmp = this.allRecords;

      if (!this.filter.showReviewed) {
        tmp = tmp.filter((r) => r.reviewStatus !== this.reviewStatuses.reviewed
          && r.reviewStatus !== this.reviewStatuses.ignored);
      }
      if (!this.filter.showNotReviewed) {
        tmp = tmp.filter((r) => r.reviewStatus !== this.reviewStatuses.notReviewed
          && r.reviewStatus !== this.reviewStatuses.ignoredNotAccepted);
      }

      if (!this.filter.showSuccess) {
        tmp = tmp.filter((r) => r.eventCardMatchStatus !== this.matchStatuses.success);
      }
      if (!this.filter.showDivergence) {
        tmp = tmp.filter((r) => r.eventCardMatchStatus !== this.matchStatuses.divergence);
      }
      if (!this.filter.showNew) {
        tmp = tmp.filter((r) => r.eventCardMatchStatus !== this.matchStatuses.newRow);
      }
      if (!this.filter.showConflict) {
        tmp = tmp.filter((r) => r.eventCardMatchStatus !== this.matchStatuses.conflict);
      }
      if (this.filter.showCurrentDC) {
        tmp = tmp.filter((r) => r.isCurrentDC);
      }

      this.filteredItems = tmp;
      this.totalCount = this.filteredItems.length;
      this.getItemsForPage();
    },
    getItemsForPage() {
      this.itemsForCurrentPage = this.filteredItems.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    },
    rowSelectable(row) {
      return (row.isCurrentDC === true);
    },
    handleSelectionChange(val) {
      this.selectedItems = val;
    },
    ignoreDisabled() {
      if (this.selectedItems.length === 0) {
        return true;
      }

      const checkStatuses = this.selectedItems.some((t) => t.reviewStatus === this.reviewStatuses.reviewed || t.reviewStatus === this.reviewStatuses.ignored);
      if (checkStatuses) return true;

      return false;
    },
    ignoreMapping() {
      if (this.selectedItems.length === 0) {
        return;
      }

      this.selectedItems.forEach((selection) => {
        if (selection.reviewStatus === this.reviewStatuses.notReviewed) {
          this.$set(selection, 'reviewStatus', this.reviewStatuses.ignoredNotAccepted);
        } else if (selection.reviewStatus === this.reviewStatuses.ignoredNotAccepted) {
          this.$set(selection, 'reviewStatus', this.reviewStatuses.notReviewed);
        }

        // проставить дочерним сущностям (карточкам ф-ий, орг причинам статусы)
        selection.functionCardMatchList.forEach((fCard) => {
          this.$set(fCard, 'reviewStatus', selection.reviewStatus);

          fCard.staffCulpabilityMatchList.forEach((st) => {
            this.$set(st, 'reviewStatus', selection.reviewStatus);
          });
        });
        selection.secondEquipmentCardMatchList.forEach((seCard) => {
          this.$set(seCard, 'reviewStatus', selection.reviewStatus);

          seCard.staffCulpabilityMatchList.forEach((st) => {
            this.$set(st, 'reviewStatus', selection.reviewStatus);
          });
        });
      });

      this.isDataChanged = true;
    },
    breakMappingDisabled() {
      if (this.selectedItems.length === 0) {
        return true;
      }

      const checkStatuses = this.selectedItems.some((t) => t.reviewStatus === this.reviewStatuses.reviewed || t.reviewStatus === this.reviewStatuses.ignored);
      if (checkStatuses) return true;

      return false;
    },
    async breakMapping() {
      if (this.selectedItems.length === 0) {
        return;
      }

      const userEventCardIds = this.selectedItems.map((t) => t.userEventCard.id);

      // разрываем через сервер
      this.loading = true;
      const res = await eventsMatchApi.breakMapping(
        this.id,
        userEventCardIds
      );
      if (res.data) {
        this.selectedItems.forEach((selectedMatch, index, array) => {
          const newMatch = res.data.find((t) => t.userEventCard.id === selectedMatch.userEventCard.id);
          newMatch.isCurrentDC = array[index].isCurrentDC; // сохранить принадлежность ДЦ

          if (newMatch) {
            Object.assign(array[index], newMatch);
          }
          this.isDataChanged = true;
        });
      }

      this.applyFilter();

      this.loading = false;
    },
    matchManuallyDisabled() {
      if (this.selectedItems.length !== 1) {
        return true;
      }

      const checkStatuses = this.selectedItems.some((t) => t.reviewStatus === this.reviewStatuses.reviewed || t.reviewStatus === this.reviewStatuses.ignored);
      if (checkStatuses) return true;

      return false;
    },
    matchManually() {
      if (this.selectedItems.length !== 1) {
        return;
      }

      this.eventSelectModalVisible = true;
    },
    handleMatchUpdate(newMatch) {
      if (!newMatch) {
        return;
      }

      Object.assign(this.selectedItems[0], newMatch);
      this.isDataChanged = true;
    },
    showFunctionCardsMatchModal(row) {
      this.selectedItem = row;
      this.matchList = row.functionCardMatchList;
      this.funcCardsMatchModalVisible = true;
    },
    showSecEqCardsMatchesModal(row) {
      this.selectedItem = row;
      this.matchList = row.secondEquipmentCardMatchList;
      this.secEqCardsMatchModalVisible = true;
    },
    handleFunctionCardsMatchStatusChange(functionCardsMatchStatus) {
      this.selectedItem.functionCardsMatchStatus = functionCardsMatchStatus;
      this.isDataChanged = true;
      this.recalculateMatchStatus();
    },
    handleSecondEquipmentCardsMatchStatusChange(secondEquipmentCardsMatchStatus) {
      this.selectedItem.secondEquipmentCardsMatchStatus = secondEquipmentCardsMatchStatus;
      this.isDataChanged = true;
      this.recalculateMatchStatus();
    },
    recalculateMatchStatus() {
      if (!this.selectedItem) return;

      if (!this.selectedItem.publicEventCard) {
        this.selectedItem.eventCardMatchStatus = this.matchStatuses.newRow;
        return;
      }

      if (this.selectedItem.functionCardsMatchStatus === this.matchStatuses.divergence
        || this.selectedItem.secondEquipmentCardsMatchStatus === this.matchStatuses.divergence
      ) {
        this.selectedItem.eventCardMatchStatus = this.matchStatuses.divergence;
        return;
      }

      if (this.selectedItem.functionCardsMatchStatus === this.matchStatuses.conflict
        || this.selectedItem.secondEquipmentCardsMatchStatus === this.matchStatuses.conflict
      ) {
        this.selectedItem.eventCardMatchStatus = this.matchStatuses.conflict;
        return;
      }

      if ((this.selectedItem.functionCardsMatchStatus === this.matchStatuses.success || this.selectedItem.functionCardsMatchStatus === this.matchStatuses.undef)
        && (this.selectedItem.secondEquipmentCardsMatchStatus === this.matchStatuses.success || this.selectedItem.secondEquipmentCardsMatchStatus === this.matchStatuses.undef)
      ) {
        this.selectedItem.eventCardMatchStatus = this.matchStatuses.success;
        return;
      }

      if ((this.selectedItem.functionCardsMatchStatus === this.matchStatuses.newRow || this.selectedItem.functionCardsMatchStatus === this.matchStatuses.undef)
        && (this.selectedItem.secondEquipmentCardsMatchStatus === this.matchStatuses.newRow || this.selectedItem.secondEquipmentCardsMatchStatus === this.matchStatuses.undef)
      ) {
        this.selectedItem.eventCardMatchStatus = this.matchStatuses.newRow;
        return;
      }

      this.selectedItem.eventCardMatchStatus = this.matchStatuses.undef;
    },
    acceptDisabled() {
      if (this.selectedItems.length === 0) {
        return true;
      }

      const checkStatuses = this.selectedItems.some((t) => t.reviewStatus === this.reviewStatuses.reviewed || t.reviewStatus === this.reviewStatuses.ignored);
        // || t.eventCardMatchStatus === this.matchStatuses.divergence
        // || t.eventCardMatchStatus === this.matchStatuses.newRow

      if (checkStatuses) return true;

      return false;
    },
    async acceptMatching() {
      this.loading = true;
      const res = await eventsMatchApi.acceptMatching(
        this.id,
        this.selectedItems
      );
      if (res.data && res.data.succeeded) {
        if (res.data.resultData) {
          this.selectedItems.forEach((selectedMatch, index, array) => {
            // событие которое было акцептовано
            const newMatch = res.data.resultData.find((t) => t.userEventCard.id === selectedMatch.userEventCard.id);
            newMatch.isCurrentDC = array[index].isCurrentDC; // сохранить принадлежность ДЦ

            if (newMatch) {
              Object.assign(array[index], newMatch);
            }
          });

          // другие события с той же карточкой пользователя которые еще не были акцептованы необходимо убрать
          // из сопоставления
          res.data.resultData.forEach((resultItem) => {
            remove(this.allRecords, (t) => t.userEventCard.id === resultItem.userEventCard.id
              && (t.reviewStatus === this.reviewStatuses.notReviewed || t.reviewStatus === this.reviewStatuses.ignoredNotAccepted));
          });

          // вызвать applyFilter чтобы пересчитать число записей
          this.applyFilter();
        }
      } else if (res.data && !res.data.succeeded) {
        if (res.data.resultMessage === 'Найдены дубликаты' && res.data.resultData) {
          // console.log(res.data.resultData);
          this.duplicatesModalVisible = true;
          this.duplicatesModalData = res.data.resultData;
        } else if (res.data.resultMessage === 'Найдены дубликаты орг. причин' && res.data.resultData) {
          // console.log(res.data.resultData);
          this.staffDuplicatesModalVisible = true;
          this.duplicatesModalData = res.data.resultData;
        }
      }
      this.loading = false;
    },
    getPublicSingleManualMatchClass(row, param) {
      if (row.reviewStatus === this.reviewStatuses.reviewed || row.reviewStatus === this.reviewStatuses.ignored || row.eventCardMatchStatus === this.matchStatuses.newRow) {
        return '';
      }

      if (row[`${param}ManualMatched`]) {
        return 'manual-match-unselected';
      }
      return 'manual-match-selected';
    },
    getUserSingleManualMatchClass(row, param) {
      if (row.reviewStatus === this.reviewStatuses.reviewed || row.reviewStatus === this.reviewStatuses.ignored || row.eventCardMatchStatus === this.matchStatuses.newRow) {
        return '';
      }

      if (row[`${param}ManualMatched`]) {
        return 'manual-match-selected';
      }
      return 'manual-match-unselected';
    },
    getParamMatchClass(row, param) {
      if (row.eventCardMatchStatus === this.matchStatuses.newRow) {
        return 'event-compare-new';
      }

      if (!row.publicEventCard) {
        return '';
      }
      if (!row.userEventCard) {
        return '';
      }
      if (!row.userEventCard[param] && !row.publicEventCard[param]) {
        return '';
      }
      if (row.userEventCard[param] !== row.publicEventCard[param]) {
        return 'event-compare-conflict';
      }

      return '';
    },
    // eslint-disable-next-line no-unused-vars
    canSingleManualMatch(row, param) {
      if (!row.isCurrentDC) {
        return false;
      }
      if (row.reviewStatus === this.reviewStatuses.reviewed || row.reviewStatus === this.reviewStatuses.ignored) {
        return false;
      }
      if (!row.publicEventCard) {
        return false;
      }
      if (!row.userEventCard) {
        return false;
      }
      return true; // row.publicEventCard[param] !== row.userEventCard[param];
    },
    applySingleManualMatch(row, param) {
      if (!this.canSingleManualMatch(row, param)) {
        return;
      }

      if (row[`${param}ManualMatched`]) {
        return;
      }

      // сохраняем данные чтобы можно было отменить действие
      if (!row.backupData) {
        row.backupData = [];
      }
      row.backupData[param] = cloneDeep(row.publicEventCard[param]);

      row.publicEventCard[param] = cloneDeep(row.userEventCard[param]);
      this.$set(row, `${param}ManualMatched`, true);
    },
    denySingleManualMatch(row, param) {
      if (!this.canSingleManualMatch(row, param)) {
        return;
      }

      if (!row[`${param}ManualMatched`]) {
        return;
      }

      if (!row.backupData || !row.backupData[param]) {
        return;
      }

      row.publicEventCard[param] = row.backupData[param];
      row.backupData[param] = null;
      this.$set(row, `${param}ManualMatched`, false);
    },
    openLongText(text) {
      this.$alert(text, '', {
        confirmButtonText: 'OK',
        customClass: 'longtext__modal'
      });
    },
  }
};
</script>

<style scoped lang="scss">
  svg {
    path{
      stroke: #e6a23c;
    }
  }
  .el-table {
    // transition: all ease 0.2s;
  }

  .el-table--border {
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
  }
  .el-table:before {
    height: 0px;
  }
  .el-table:after {
    width: 0px;
  }


  /*.box2:hover {
    transform: translateY(3px);
    box-shadow: inset 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  }*/
</style>
