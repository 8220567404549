<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="1">
      <template v-slot:title>
        <span>
          <span>Фильтр</span>
          <el-button class="ml-5"
              plain
              size="mini"
              type="default" 
              @click.stop="clearFilters" 
              style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </span>
      </template>
      <el-row class="m-0 filter">
        <el-col :span="8" class="ml-2 filter--container">
          <span class="mr-2" style="min-width:8rem;">Энергообъект:</span>
          <remote-search-input v-model="filterModel.energyObjectFilter"
                               searchUrl="/api/energyObjects/SearchByName"
                               :allow-create=false
                               :multiple="true"
                               :start-length="3" />
        </el-col>
        <el-col :span="8" class="ml-2 filter--container">
          <span class="mr-2" style="min-width:14rem;">Первичное оборудование/ЛЭП:</span>
          <remote-search-input
            v-model="filterModel.primaryEquipmentFilter"
            searchUrl="/api/primaryEquipments/SearchPrimaryEquipmentsWithLine"
            :allow-create=false
            :multiple="true"
            :start-length=3
          />
        </el-col>
        <el-col :span="8" class="ml-2 filter--container">
          <span class="mr-2" style="min-width:8rem;">Дата события:</span>
          <el-date-picker v-model="filterModel.startEventTimeFilter"
                          v-mask="'##.##.#### ##:##'"
                          size="small"
                          style="width: 100%"
                          type="datetime"
                          range-separator="-"
                          placeholder="дд.мм.гггг чч:мм"
                          format="dd.MM.yyyy HH:mm"
                          :picker-options="datePickerOptions"/>
          <span class="m-2">-</span>
          <el-date-picker v-model="filterModel.endEventTimeFilter"
                          v-mask="'##.##.#### ##:##'"
                          size="small"
                          style="width: 100%"
                          type="datetime"
                          range-separator="-"
                          placeholder="дд.мм.гггг чч:мм"
                          format="dd.MM.yyyy HH:mm"
                          :default-time="'23:59:00'"
                          :picker-options="datePickerOptions"/>
        </el-col>
      </el-row>
      <el-row class="mt-2 filter">
        <el-col :span="8" class="ml-2 filter--container">
          <span class="mr-2" style="min-width:8rem;">Энергосистема:</span>
          <!-- <remote-search-input v-model="filterModel.eoRegionFilter"
                               searchUrl="/api/regions/SearchByName"
                               :allow-create=false
                               :multiple="true"
                               :start-length=3
          /> -->
          <el-select multiple filterable :collapse-tags="filterModel.eoRegionFilter.length > 3"
                      clearable v-model="filterModel.eoRegionFilter" size="small"
                      popper-class="search-select">
            <div class="pl-3">
            </div>
            <el-option
              v-for="item in operationalZones"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8" class="ml-2 filter--container">
          <span class="mr-2" style="min-width:14rem;">Напряжение:</span>
          <el-select multiple filterable :collapse-tags="filterModel.baseVoltageFilter.length > 5" clearable v-model="filterModel.baseVoltageFilter" size="small">
            <div class="pl-3">
              <el-button type="text" size="small" @click="filterModel.baseVoltageFilter = selectAllValues($event)">Выбрать всё</el-button>
            </div>
            <el-option
              v-for="item in baseVoltages"
              :key="item.id"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8" class="ml-2 filter--container">
        </el-col>
      </el-row>
      <!-- <el-row class="m-0 filter">
        <el-col :span="8" class="ml-2 filter--container">
          <el-button @click="clearFilters" type="text" style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </el-col>
      </el-row> -->
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from 'vuex';
import dateHelpers from '@/mixins/dateHelpers';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';

export default {
  name: 'EventsSelectModalFilter',
  mixins: [dateHelpers],
  props: {
    filterModel: { type: Object, default: () => {} },
  },
  components: { RemoteSearchInput },
  data() {
    return {
      filter: ['1'],
    };
  },
  computed: {
    ...mapGetters('dictionaries', ['baseVoltages', '']),
  },
  methods: {
    handleChange(val) {
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        // this.filterModel.isActive = true;
        this.$emit('active-change', true);
       } else {
         // this.filterModel.isActive = false;
        this.$emit('active-change', false);
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    },
    selectAllValues(event /* dict */) {
      const allValues = [];
      /*
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.baseVoltages) {
          allValues.push(item.name);
      }  */
      // временное решение, но вроде в принципе работает нормально
      const options = event.target.parentNode.parentNode.parentNode.querySelectorAll('li:not([style="display: none;"])');
      options.forEach((el) => (allValues.push(el.innerText)));

      return allValues;
    },
  }
};
</script>

<style scoped lang="scss">

</style>
